@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
}

body {
  position: fixed; /* iOS Safari에서 스크롤/바운스 효과 방지 */
  -webkit-overflow-scrolling: touch !important;
  height: 100vh;
  width: 100vw;
  user-select: none;
}

.bottomButton {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  z-index: 1;
  margin-bottom: 40px;
  font-size: 17px;
  line-height: 20px;
}

.bottomButtonOnBottomNav {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  z-index: 1;
  margin-bottom: calc(6rem + env(safe-area-inset-bottom));
  font-size: 17px;
  line-height: 20px;
}

/* Place the navbar at the bottom of the page, and make it stick */
.bottomNavbar {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  max-width: calc(var(--chakra-sizes-xl));
  width: 100%;
  background-color: #ffffff;
}

.darkBottomNavbar {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  max-width: calc(var(--chakra-sizes-xl));
  width: 100%;
  background-color: #0d0e12;
}

.bottomCollectionNavbar {
  overflow: hidden;
  position: fixed;
  bottom: 36;
  width: 100%;
  background-color: #ffffff;
}

.tradingBottomSheetHeader {
  min-height: 66px;
  padding-top: 13px;
  padding-left: 16px;
  padding-right: 16px;
}

.bottomSheetHeader {
  padding-top: 21px;
  padding-bottom: 21px;
  padding-left: 16px;
  padding-right: 16px;
}

.bottomSheetContent {
  padding-left: 24px;
  padding-right: 24px;
}

@media (min-width: 1280px) {
  .bottomNavbar {
    border-color: #ffffff;
    border-bottom-width: 10px;
    background-color: rgba(255, 255, 255, 1);
  }

  .darkBottomNavbar {
    border-color: #0d0e12;
    border-bottom-width: 10px;
    background-color: #0d0e12;
  }
}

div.horizontalScroll {
  margin-top: 0px !important;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

div.horizontalScroll img {
  display: inline-block;
  margin-right: 10px;
}

div.horizontalScroll div {
  display: inline-block;
}

div.horizontalScrollOnly {
  margin-top: 0px !important;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.video-background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -0.5;
}

@media (min-width: 1280px) {
  .homeBackground {
    background: linear-gradient(
        180deg,
        #fff 0%,
        #fff 6%,
        rgba(255, 255, 255, 0.3) 20%,
        #fff 35%,
        #fff 100%
      ),
      linear-gradient(90deg, #f0d6ff 0%, #fff 50%, #bddcff 100%);
  }
}

.homeBackground {
  background: linear-gradient(
      180deg,
      #fff 0%,
      #fff 6%,
      rgba(255, 255, 255, 0.3) 20%,
      #fff 35%,
      #fff 100%
    ),
    linear-gradient(90deg, #f0d6ff 0%, #fff 50%, #bddcff 100%);
}

@media (min-width: 1280px) {
  .darkHomeBackground {
    background: linear-gradient(
        180deg,
        #0d0e12 0%,
        #0d0e12 5.61%,
        rgba(255, 255, 255, 0.3) 19.79%,
        #0d0e12 32.46%,
        #0d0e12 100%
      ),
      linear-gradient(90deg, #430d47 0%, #0d0e12 50%, #173659 100%);
  }
}

.darkHomeBackground {
  background: linear-gradient(
      180deg,
      #0d0e12 0%,
      #0d0e12 5.61%,
      rgba(0, 0, 0, 0.03) 19.79%,
      #0d0e12 32.46%,
      #0d0e12 100%
    ),
    linear-gradient(90deg, #430d47 0%, #0d0e12 50%, #173659 100%);
}

.gradientBackground {
  background: linear-gradient(0deg, #fff 62.76%, #eeeff2 100%);
}

.darkGradientBackground {
  background: #0d0e12;
}

.hiddenScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hiddenScrollBar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* 바텀시트 여백 */
.react-modal-sheet-container {
  padding-bottom: calc(env(safe-area-inset-bottom));
}

/* [Cross-Browsing] iPhone input/textarea */
input,
textarea {
  -webkit-user-select: auto;
}

.bottomInput {
  overflow: hidden;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #ffffff;
}

@media (min-width: 1280px) {
  .bottomInput {
    border-color: #ffffff;
    border-bottom-width: 10px;
    background-color: rgba(255, 255, 255, 1);
  }
}

/* Override styles for .react-horizontal-scrolling-menu--scroll-container */
.padding-left-scroll-container
  .react-horizontal-scrolling-menu--scroll-container {
  padding-left: 24px;
  padding-right: 24px;
  scroll-snap-align: start;
  gap: 4px;
}
.fixed-height-scroll-container
  .react-horizontal-scrolling-menu--scroll-container {
  height: 87px;
}

.home-cirlces-padding-left-scroll-container
  .react-horizontal-scrolling-menu--scroll-container {
  padding-left: 16px;
  padding-right: 16px;
}

.nogap-padding-left-scroll-container
  .react-horizontal-scrolling-menu--scroll-container {
  padding-left: 20px;
  padding-right: 20px;
  scroll-snap-align: start;
  gap: 0px;
}
